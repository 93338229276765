<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="queryData" class="demo-form-inline search-form">
				<el-form-item label="标题：">
					<el-autocomplete v-model.trim="queryData.title" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item label="卫生服务团队：" prop="healthServiceGroupId">
					<el-select v-model="queryData.healthServiceGroupId">
						<el-option v-for="item in healthServiceGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<div class="clearfix">
					<el-button class="float-r" type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
					<div class="changeTabs float-r">
						<span :class="{ activeClass: checkTab == 'E' }" @click="changeCheckTab('E')">健康教育</span>
						<span :class="{ activeClass: checkTab == 'P' }" @click="changeCheckTab('P')">健康计划</span>
						<!-- <span :class="{ activeClass: checkTab == 'F' }" @click="changeCheckTab('F')">健康指导</span> -->
					</div>
				</div>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #showEvaluate="{ scope }">
					<el-button @click="seeEvaluate(scope.row)" type="text"> 查看 </el-button>
				</template>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView @getList="getList" ref="createView" :isShowDetail="isShowDetail" />
		<ShowEvaluate ref="showEvaluate" />
	</div>
</template>

<script>
export default {
	name: 'healthService',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
		ShowEvaluate: () => import('./showEvaluate.vue'),
	},
	data() {
		return {
			loading: false,
			queryData: {
				current: 1,
				title: '',
				size: 10,
				healthServiceGroupId: null,
			},
			total: 0,
			options: [],
			tableData: [],
			healthServiceGroupList: [],
			tableHead: [
				{
					label: '标题',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '卫生服务团队',
					prop: 'healthServiceGroup',
					formatter: (row) => {
						return row.healthServiceGroup?.title || '-';
					},
				},
				{
					label: '学时',
					prop: 'creditHour',
					formatter: (row) => {
						return row.creditHour || '-';
					},
				},
				{
					label: '查看评价',
					prop: 'showEvaluate',
				},
				{
					label: '创建时间',
					prop: 'createTime',
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			filterLoading: false,
			isShowDetail: false,
			link: '',
			checkTab: 'E',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
		this.getHealthServiceGroupList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch(data) {
			this.queryData.current = 1;
			this.getList('', data);
		},
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 获取健康服务团队列表
		getHealthServiceGroupList() {
			this.$http
				.get(this.api['HealthServiceGroups#index'].href, { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.healthServiceGroupList = res.data.collection || [];
						this.healthServiceGroupList.unshift({
							id: null,
							name: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		changeCheckTab(type) {
			this.checkTab = type;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.queryData,
				...this.formInline,
				discriminationCode: this.checkTab,
			};
			this.$http
				.get(this.api['HouseholdEducations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				link: !row ? this.link : row._links.update.href,
				row: row ? row : null,
				discriminationCode: this.checkTab,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		seeEvaluate(row) {
			let data = {
				discriminationCode: this.checkTab,
				row,
			};
			this.$refs.showEvaluate.init(data);
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
		.changeTabs {
			border: 1px solid #dcdfe6;
			border-radius: 6px;
			line-height: 32px;
			height: 32px;
			margin-top: 8px;
			margin-right: 10px;
			span {
				padding: 5px 16px;
				cursor: pointer;
			}
		}
		.activeClass {
			background-color: #eff4ff;
			color: #1db9b1;
		}
	}
}
</style>
